import React from 'react'
import classNames from 'classnames'
import Header, { HeaderTheme } from 'Components/layout/Header'
import Footer, { FooterProps } from 'Components/layout/Footer'
import styles from './Layout.module.scss'

export type LayoutProps = {
  className?: string
  children: React.ReactNode
  headerTheme?: HeaderTheme
  footerProps?: FooterProps
  overflow?: boolean
}

const Layout = (props: LayoutProps) => {
  const { className = '', children, headerTheme, footerProps, overflow = true } = props

  return (
    <main
      className={classNames([styles.root, className, { [styles.overflow]: overflow }])}
    >
      <Header theme={headerTheme} />
      {children}
      <Footer {...footerProps} />
    </main>
  )
}

export default Layout
